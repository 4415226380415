import React, {
  createContext,
  FC,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { IAddress } from "../../models/address/IAddress";
import { ICardItem } from "../../models/payment/ICard";
import { PaymentType } from "../../models/payment/IPay";
import { IOrder } from "../../models/order/IOrder";
import DeliveryApi from "../../services/Api/delivery/DeliveryApi";
import { useHistory, useLocation } from "react-router-dom";
import { IOrderDetail } from "../../models/order/aggregates/datail/IOrderDetail";
import { IStoreConfig } from "../../models/local/ILocalConfig";
import { OrderApi } from "../../services/Api/order/OrderApi";
import { useUi } from "../ui/UIContext";
import { PaymentApi } from "../../services/Api/payment/PaymentApi";
import { IConfirmarPagamento } from "../../models/payment/IPaymentApi";
import { DeliveryType } from "../../models/delivery/deliveryFee";
import { Drawer } from "@material-ui/core";
import { SecurityCodeInput } from "./_securityCodeInput/SecurityCodeInput";
import { useParamsConfig } from "../paramsConfig/ParamsConfigContext";
import { AccountApi } from "../../services/Api/account/_AccountApi";
import { IPayOrRecharge } from "../../models/payment/IPayOrRecharge";
import { UseMercadoPago } from "./UseMercadoPago";
import UseFingerPrint from "./UseFingerPrint";
import UseLocalOrderPads, { IOrderPads } from "pages/payment/UseLocalOrderPads";
import UseCoupon from "pages/payment/coupon/UseCoupon";
import { ICoupon } from "models/coupon/ICoupon";
import CalcCoupon from "pages/payment/coupon/CalcCoupon";
import UseServiceFee from "./UseServiceFee";

declare global {
  interface Window {
    deviceId: string;
    onGetFingerPrint: (value: any) => void;
  }
}

export interface IPixValues {
  qrCode: string;
  key: string;
}
interface IPaymentContext {
  orderDetail: IOrderDetail | null;
  address: IAddress | null;
  setAddress: (address: IAddress | null) => void;
  setPaymentType: (paymentType: PaymentType) => void;
  paymentType: PaymentType;
  setChange: (change: number) => void;
  change: number | null;
  setInstallment: (installment: number) => void;
  installment: number | null;
  card: ICardItem | null;
  setCard: (card: ICardItem | null) => void;
  deliveryFee: number | null;
  errorPayment: IErrorPayment;
  confirmarPagamento: () => void;
  storeConfig: IStoreConfig | null;
  deliveryType: DeliveryType;
  setDeliveryType: (deliveryType: DeliveryType) => void;
  setCustomDeliveryDescription: (value: string) => void;
  customDeliveryDescription: string;
  orderPads?: IOrderPads[];
  setSelectedOrderPad?: (orderPad: IOrderPads) => void
  selectedOrderPad?: IOrderPads,
  selectedCoupon: ICoupon | undefined,
  isLoadingCoupon: boolean,
  activateCoupon: (code: string) => void,
  coupons: ICoupon[] | undefined,
  setSelectedCoupon: (coupon: ICoupon | undefined) => void,
  serviceFee?: number
  setAcceptServiceFee: (value: boolean) => void
  pixValues?: IPixValues
  enviarMensagemDeConfirmacao: () => void
  confimPlaceModal: boolean,
  onClickConfirmarAndOpenPlaceAlert: () => void
  closePlaceAlert: () => void,
  updateTableLocalStorage: (table: IOrderPads) => void;
  updatePaymentTypeLocalStorage: (paymentType: PaymentType) => void
}

export interface IErrorPayment {
  resume?: {
    message: string;
    object: IOrder | null;
  };
  address?: {
    message: string;
    object: IAddress | null;
  };
  paymentType?: {
    message: string;
    object: PaymentType;
  };
  change?: number | null;
  card?: {
    message: string;
    object: ICardItem | null;
  };
  deliveryFee?: {
    message: string;
    object: Number | null;
  };
}

export interface IPayment {
  resume?: IOrder | null;
  address?: IAddress | null;
  paymentType?: PaymentType;
  change?: number | null;
  card?: ICardItem | null;
  deliveryFee?: number | null;
}

const PaymentContext = createContext<IPaymentContext>({} as IPaymentContext);

export const PaymentProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { showLoading, hideLoading, toastFullScreen, toast } = useUi();
  // const { orderId } = useParams<{ orderId: string }>();
  // const query = new URLSearchParams(useLocation().search);

  const { queryParams } = useParamsConfig();
  const { isIframe } = useUi();

  //dados do pedido e da configuração do pagamento
  const [orderDetail, setOrderDetail] = useState<IOrderDetail | null>(null);
  const [storeConfig, setStoreConfig] = useState<IStoreConfig | null>(null);

  //mesa e comanda
  const { orderPads, setSelectedOrderPad, selectedOrderPad } = UseLocalOrderPads(storeConfig);
  const [confimPlaceModal, setConfimPlaceModal] = useState(false)

  //Valores para montagem do objeto de pagamento
  const [inPayment, setInPayment] = useState(false);
  const [paymentType, setPaymentType] = useState<PaymentType>(
    PaymentType.NAO_INFORMADO
  );
  const [address, setAddress] = useState<IAddress | null>(null);
  const [deliveryType, setDeliveryType] = useState<DeliveryType>(
    queryParams?.queryTypeDelivery === DeliveryType.other.toString()
      ? DeliveryType.other
      : DeliveryType.undefined
  );

  const [card, setCard] = useState<ICardItem | null>(null);
  const [change, setChange] = useState<number | null>(null);
  const [deliveryFee, setdeliveryFee] = useState<number | null>(null);
  const [customDeliveryDescription, setCustomDeliveryDescription] =
    useState("");
  const [installment, setInstallment] = useState<number>(1);

  //Validação tela de pagamento
  const [errorPayment, setErrorPayment] = useState<IErrorPayment>({});
  const [paymentObject, setPaymentObject] = useState<IConfirmarPagamento | null>(null);
  const [securityCodeOpen, setSecurityCodeOpen] = useState(false);

  //device Id pagseguro
  const { deviceIdMercadoPago } = UseMercadoPago();
  const { fingerPrint } = UseFingerPrint();

  //Pix values

  const [pixValues, setPixValues] = useState<IPixValues>()

  //navegação
  const history = useHistory();
  const location = useLocation();

  const {
    selectedCoupon,
    isLoadingCoupon,
    activateCoupon,
    coupons,
    setSelectedCoupon
  } = UseCoupon()

  const { serviceFee, setAcceptServiceFee } = UseServiceFee(orderDetail, storeConfig)

  const updateTableLocalStorage = useCallback((table: IOrderPads) => {
    sessionStorage.setItem(`@Meep:table`, JSON.stringify(table));
    setSelectedOrderPad(table);
  }, [setSelectedOrderPad]);

  const updatePaymentTypeLocalStorage = useCallback((paymentType: PaymentType) => {
    sessionStorage.setItem(`@Meep:paymentType`, JSON.stringify(paymentType));
    setPaymentType(paymentType);
  }, []);

  const getOrderAndConfig = useCallback(async (orderId: string) => {
    showLoading();
    const storageTable = sessionStorage.getItem(`@Meep:table`);
    const storagePaymentType = sessionStorage.getItem(`@Meep:paymentType`);

    try {
      const orderApi = OrderApi();
      const response = await orderApi.getOrderAndConfigs(orderId);
      if (response) {
        setStoreConfig(response.data.store);
        setOrderDetail(response.data.order);
        storageTable && updateTableLocalStorage(JSON.parse(storageTable) as IOrderPads);
        storagePaymentType && updatePaymentTypeLocalStorage(JSON.parse(storagePaymentType) as PaymentType);
      }
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toastFullScreen(
          error?.response?.data?.message,
          "error",
          "error",
          3000,
          () => {
            history.replace("/");
          }
        );
      } else {
        toastFullScreen("Pedido Não Encontrado", "error", "error", 3000, () => {
          history.replace("/");
        });
      }
    }
    hideLoading();

  }, [hideLoading, history, showLoading, toastFullScreen, updatePaymentTypeLocalStorage, updateTableLocalStorage]);

  const redirectSucess = useCallback(() => {
    if (isIframe) {
      window.open(`/payment/confirm-payment/${storeConfig?.storeId}`);
      window.parent.postMessage({ command: "SUCESS_PAYMENT" }, "*");
    } else {
      history.replace(`/payment/confirm-payment/${storeConfig?.storeId}`);
    }
  }, [history, isIframe, storeConfig]);

  useEffect(() => {
    if (queryParams?.queryPlace && !selectedOrderPad) {
      setDeliveryType(DeliveryType.other)
      setCustomDeliveryDescription(`${queryParams?.queryDescription ?? storeConfig?.customDeliveryDescription} ${queryParams?.queryPlace}`)
    }

    return () => {

    }
  }, [queryParams, selectedOrderPad, storeConfig])


  const getAccountAndConfig = useCallback(async (accountId: string) => {
    const accountApi = AccountApi();
    showLoading();
    try {
      const response = await accountApi.getAccount(accountId);
      if (response) {
        setStoreConfig(response.data.store);
        setOrderDetail(response.data.order);
      }
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toastFullScreen(
          error?.response?.data?.message,
          "error",
          "error",
          3000,
          () => {
            history.replace("/");
          }
        );
      } else {
        toastFullScreen("Conta Não Encontrada", "error", "error", 3000, () => {
          history.replace("/");
        });
      }
    }
    hideLoading();
  }, [hideLoading, history, showLoading, toastFullScreen]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const orderId = query.get("orderId");
    const accountId = query.get("orderId");
    if (orderId) {
      getOrderAndConfig(orderId);
    } else if (accountId) {
      getAccountAndConfig(accountId);
    } else {
      if (queryParams) {
        if (queryParams.orderId) {
          getOrderAndConfig(queryParams.orderId);
        } else if (queryParams?.accountId) {
          getAccountAndConfig(queryParams.accountId);
        } else {
          history.replace("/");
        }
      }
    }
  }, [getAccountAndConfig, getOrderAndConfig, history, location.search, queryParams]);

  const enviarMensagemDeConfirmacao = useCallback(() => {
    let mensagem = `Pedido Enviado \n Acompanhe o status do pedido.`;
    let actionButton = `Ver Pedido`;
    setPixValues(undefined);
    if (queryParams?.queryTypeDelivery === "3") {
      // mensagem = `Pedido realizado com sucesso\n ${queryParams?.queryDescription ?? ""} ${queryParams?.queryPlace ?? ""}\n Sera um gerado um QrCode para se apresentado referente ao seu pedido.`
      mensagem = `PARABÉNS PELA SUA COMPRA!\n\n\nÉ só fazer o seguinte:\n\n\n1-Aguarde seu pedido ficar pronto.\n\n2-${queryParams?.queryDescription ?? ""
        } ${queryParams?.queryPlace ?? ""
        }\n\n3-No aplicativo entre no menu pedidos e clique no ícone do QRCode.\n\n4-Um QRCode será gerado. É só apresentar no ponto de venda!\n\n`;
      actionButton = "Entendi";
    }
    toastFullScreen(
      mensagem,
      "check",
      "success",
      30000,
      redirectSucess,
      actionButton
    );
  }, [queryParams, toastFullScreen, redirectSucess]);

  const enviarPagamentoPedido = useCallback(
    async (paymentRequest: IConfirmarPagamento) => {
      try {
        showLoading();
        setInPayment(true);

        const paymentApi = PaymentApi();
        const response = await paymentApi.confirmarPagamentoPedido(
          paymentRequest
        );
        if (response) {
          if (response.data.qrcodeBase64 && response.data.qrcodeLink) {
            setPixValues({ qrCode: response.data.qrcodeBase64, key: response.data.qrcodeLink })
          } else {
            enviarMensagemDeConfirmacao();
          }
        }
      } catch (error: any) {
        if (error?.response?.data?.message) {
          toast(error?.response?.data?.message, "error");
        }
      } finally {
        hideLoading();
        setInPayment(false);
        sessionStorage.removeItem(`@Meep:cart`);
      }
    },
    [enviarMensagemDeConfirmacao, hideLoading, showLoading, toast]
  );

  const enviarPagamentoConta = useCallback(
    async (paymentRequest: IConfirmarPagamento) => {
      const paymentApi = PaymentApi();
      try {
        showLoading();
        setInPayment(true);
        if (paymentRequest.dadosCartaoCadastrado && queryParams?.accountId) {
          const requestPayment: IPayOrRecharge = {
            accountId: queryParams?.accountId,
            value: paymentRequest.resumoPedido.valorPedido,
            creditCardId: paymentRequest.dadosCartaoCadastrado?.cartaoId,
            CVV: paymentRequest.dadosCartaoCadastrado?.codigoSeguranca,
            deviceId: deviceIdMercadoPago,
          };
          const response = await paymentApi.payOrRecharge(requestPayment);

          if (response) {
            enviarMensagemDeConfirmacao();
          }
        }
      } catch (error: any) {
        if (error?.response?.data?.message) {
          toast(error?.response?.data?.message, "error");
        }
      } finally {
        hideLoading();
        setInPayment(false);
      }
    },
    [deviceIdMercadoPago, enviarMensagemDeConfirmacao, hideLoading, queryParams, showLoading, toast]
  );

  /**
   * Efetuar pagamento e insere o codigo de segurança contido no contexto atual
   */
  const inserirCodigoDeSeguracaEEnviarPagamento = useCallback(
    async (paymentObject: IConfirmarPagamento, securityCode?: string) => {
      if (paymentObject) {
        const paymentRequest: IConfirmarPagamento = securityCode
          ? {
            ...paymentObject,
            mesaId: selectedOrderPad?.id,
            dadosCartaoCadastrado: paymentObject.dadosCartaoCadastrado
              ? {
                ...paymentObject.dadosCartaoCadastrado,
                codigoSeguranca: securityCode,
              }
              : undefined,
          }
          : paymentObject;

        if (queryParams?.orderId) {
          await enviarPagamentoPedido(paymentRequest);
        } else if (queryParams?.accountId) {
          await enviarPagamentoConta(paymentRequest);
        }
      }
    },
    [queryParams, selectedOrderPad, enviarPagamentoPedido, enviarPagamentoConta]
  );

  const enviarPagamentoOrderComSecurityCode = useCallback(
    (securityCode?: string) => {
      if (paymentObject) {
        inserirCodigoDeSeguracaEEnviarPagamento(paymentObject, securityCode);
      }
    },
    [inserirCodigoDeSeguracaEEnviarPagamento, paymentObject]
  );

  const validatePaymentForm = useCallback(
    (paymentObject: IConfirmarPagamento) => {
      if (
        storeConfig?.allowDeliveryHome ||
        storeConfig?.allowCatchOnShop ||
        storeConfig?.allowCustomDelivery
      ) {
        if (deliveryType === 0) {
          toast("Informe o local de entrega", "error");
          return false;
        }
        if (
          deliveryType === DeliveryType.delivery &&
          !paymentObject.enderecoEntregaId
        ) {
          toast("Informe o endereço de entrega", "error");
          return false;
        }
        if (
          deliveryType === DeliveryType.other &&
          queryParams?.queryPlace + customDeliveryDescription === ""
        ) {
          toast("Informe o local de entrega", "error");
          return false;
        }
        if (
          deliveryType === DeliveryType.other && storeConfig.allowTableDelivery && !selectedOrderPad
        ) {
          toast("Informe o local de entrega válido", "error");
          return false;
        }
      }
      if (paymentObject.tipoPagamentoId === 0) {
        toast("selecione uma forma de pagamento", "error");
        return false;
      }
      return true;
    },
    [storeConfig, deliveryType, queryParams, customDeliveryDescription, selectedOrderPad, toast]
  );

  useEffect(() => {
    if (
      storeConfig?.storeId &&
      address &&
      deliveryType === DeliveryType.delivery
    ) {
      DeliveryApi.getDeliveryFee(storeConfig.storeId, address?.id)
        .then((response) => {
          setOrderDetail((prev) =>
            prev ? { ...prev, deliveryFee: response?.data?.price || 0 } : null
          );
          setdeliveryFee(response.data.price);
          setErrorPayment((prev) => ({
            ...prev,
            address: undefined,
          }));
        })
        .catch((error) => {
          setAddress(null);
          setdeliveryFee(null);
          toast(error?.response?.data.message, "warning");
          setErrorPayment((prev) => ({
            ...prev,
            address: {
              object: address,
              message:
                error?.response?.data?.message ?? "Endereço não é valido",
            },
          }));
        });
    } else {
      setOrderDetail((prev) => (prev ? { ...prev, deliveryFee: 0 } : null));
      setdeliveryFee(0);
    }
    return () => { };
  }, [address, storeConfig, deliveryType, toast]);

  /**
   * Monta o pagamento e valida o formulario de pagamento, caso seja cartao abre modal do codigo de segurança.
   */
  const confirmarPagamento = useCallback(async () => {
    if (orderDetail && storeConfig) {
      const paymentObject: IConfirmarPagamento = {
        localClienteId: storeConfig.storeId,
        enderecoEntregaId:
          deliveryType === DeliveryType.delivery && address
            ? address.id
            : undefined,
        customDeliveryDescription: `${customDeliveryDescription}`,
        tipoPagamentoId: paymentType === PaymentType.PIX ? PaymentType.DEBITO : paymentType,
        mesaId: selectedOrderPad?.id,
        troco: change ?? 0,
        parcelas: installment,
        dadosCartaoCadastrado: card
          ? { cartaoId: card.id, codigoSeguranca: "" }
          : undefined,
        resumoPedido: {
          pedidoId: queryParams?.orderId,
          valorPedido: orderDetail?.amount,
          taxaEntrega: deliveryFee ?? undefined,
          taxaServico: serviceFee,
          cupom: selectedCoupon && {
            id: CalcCoupon(orderDetail?.amount, selectedCoupon).id,
            valor: CalcCoupon(orderDetail?.amount, selectedCoupon).value
          }
        },
        fingerPrint: deviceIdMercadoPago,
        canalVenda: 1,
        deviceFingerPrint: fingerPrint?.visitorId,
        gerarPIX: paymentType === PaymentType.PIX ? true : false
      };
      setPaymentObject(paymentObject);
      if (validatePaymentForm(paymentObject)) {
        if (card) {
          // setSecurityCodeOpen(true);
          enviarPagamentoOrderComSecurityCode();
        } else {
          inserirCodigoDeSeguracaEEnviarPagamento(paymentObject);
        }
      }
    }
  }, [orderDetail, storeConfig, deliveryType, address, customDeliveryDescription, paymentType, selectedOrderPad, change, installment, card, queryParams, deliveryFee, serviceFee, selectedCoupon, deviceIdMercadoPago, fingerPrint, validatePaymentForm, enviarPagamentoOrderComSecurityCode, inserirCodigoDeSeguracaEEnviarPagamento]);

  const onClickConfirmarAndOpenPlaceAlert = useCallback(() => {
    if (selectedOrderPad) {
      setConfimPlaceModal(true);
    } else {
      confirmarPagamento()
    }
  }, [confirmarPagamento, selectedOrderPad])

  const closePlaceAlert = useCallback(() => {
    setConfimPlaceModal(false)
  }, [])

  return (
    <PaymentContext.Provider
      value={{
        orderDetail: orderDetail,
        setAddress,
        address,
        setPaymentType,
        paymentType,
        change,
        setChange,
        card,
        setCard,
        deliveryFee,
        errorPayment,
        confirmarPagamento,
        storeConfig,
        deliveryType,
        setDeliveryType,
        setCustomDeliveryDescription,
        customDeliveryDescription,
        setInstallment,
        installment,
        orderPads,
        selectedOrderPad,
        setSelectedOrderPad,
        selectedCoupon,
        isLoadingCoupon,
        activateCoupon,
        coupons,
        setSelectedCoupon,
        serviceFee,
        setAcceptServiceFee,
        pixValues,
        enviarMensagemDeConfirmacao,
        confimPlaceModal,
        onClickConfirmarAndOpenPlaceAlert,
        closePlaceAlert,
        updateTableLocalStorage,
        updatePaymentTypeLocalStorage,
      }}
    >
      {children}
      <Drawer
        anchor="bottom"
        open={securityCodeOpen}
        onClose={() => setSecurityCodeOpen(false)}
      >
        <SecurityCodeInput
          loading={inPayment}
          onSubmit={enviarPagamentoOrderComSecurityCode}
        />
      </Drawer>
    </PaymentContext.Provider>
  );
};

export const PaymnetConsumer = PaymentContext.Consumer;

export const usePayment = () => {
  return useContext(PaymentContext);
};
