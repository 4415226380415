import { ITotalsComposition } from 'components/productComposition/interfaces/ITotalsComposition';
import { useCart } from 'context/cart/CartContext';
import { IProductComposition } from 'models/catalog/aggregates/Composition';
import { Product } from 'models/catalog/aggregates/Product';
import { IOrderComposition } from 'models/order/IOrderComposition';
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { v4 } from 'uuid';
import { useCatalog } from '../../../../context/catalog/CatalogContext'

interface useProductDetailProps {
    productId: string;
}

const useProductDetail = ({ productId }: useProductDetailProps) => {
    const { replace } = useHistory();
    const { url, storeId } = useParams<{ url: string, storeId: string }>();

    const { getProductFromCatalog } = useCatalog();
    const [product, setProduct] = useState<Product>()
    const [quantity, setQuantity] = useState(1);
    const [observationField, setObservationField] = useState<string>()
    const [compositions, setCompositions] = useState<{ orderComposition: IOrderComposition[], totals: ITotalsComposition, unvalidatedCompositions: IProductComposition[] }>();
    const { addProductCart } = useCart();


    useEffect(() => {
        if (productId) {
            const _product = getProductFromCatalog(productId);
            setProduct(_product);
        }
        return () => {

        }
    }, [getProductFromCatalog, productId])

    const onClickAddQuantityHandle = useCallback(() => {
        setQuantity(prev => prev + 1);
    }, [])
    const onClickRemoveQuantityHandle = useCallback(() => {
        setQuantity(prev => prev > 1 ? prev - 1 : 1);
    }, [])

    const onClickBack = useCallback(() => {
        replace(`/${url}/${storeId}`)
    }, [replace, url, storeId]);

    const onClickAddProduct = useCallback(() => {
        if (product) {
            const _composition: IOrderComposition[] = [...(compositions?.orderComposition ? compositions.orderComposition : [])]

            const _observationField = observationField ? {
                id: v4(),
                description: 'Observação',
                compositionItems: [
                    {
                        id: v4(),
                        description: observationField,
                        price: 0,
                        totalPrice: 0,
                        quantity: 1,
                    }
                ]
            } : null

            addProductCart(product, quantity, _observationField ? [..._composition, _observationField] : _composition);
            onClickBack();
        }
    }, [product, compositions, observationField, quantity, addProductCart, onClickBack]);

    const onChangeCompositionHandle = useCallback(
        (orderComposition: IOrderComposition[], totals: ITotalsComposition, unvalidatedCompositions: IProductComposition[]) => {
            setCompositions({ orderComposition, totals, unvalidatedCompositions })
        },
        [],
    )

    const handleChangeObservation = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = evt.target;
        setObservationField(value);
    };

    return (
        {
            product,
            compositions,
            quantity,
            onClickAddQuantityHandle,
            onClickRemoveQuantityHandle,
            onClickAddProduct,
            onChangeCompositionHandle,
            onClickBack,
            handleChangeObservation
        }
    )
}
export default useProductDetail
