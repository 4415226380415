import { CardBrandRule, CardBrandType } from "models/payment/ICard";

export default class Utils {
  public static toDateAndTime(date: Date | string, adjusteUTC?: boolean) {
    date = date instanceof Date ? date : new Date(date);
    const adjustedDate = new Date(new Date(date.getTime() - (3 * 60 * 60 * 1000)).toLocaleString("pt-BR"));
    return `${(adjusteUTC ? adjustedDate : date).toLocaleDateString([], {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    })} - ${(adjusteUTC ? adjustedDate : date).toLocaleTimeString([], {
      timeStyle: "short",
      hour12: false
    })}`;
  }
  
  public static toDate(date: Date | string, adjusteUTC?: boolean) {
    date = date instanceof Date ? date : new Date(date);
    const adjustedDate = new Date(new Date(date.getTime() - (3 * 60 * 60 * 1000)).toLocaleString("pt-BR"));
    return `${(adjusteUTC ? adjustedDate : date).toLocaleDateString([], {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })}`;
  }
  
  public static toTime(date: Date | string, adjusteUTC?: boolean) {
    date = date instanceof Date ? date : new Date(date);
    const adjustedDate = new Date(new Date(date.getTime() - (3 * 60 * 60 * 1000)).toLocaleString("pt-BR"));
    return `${(adjusteUTC ? adjustedDate : date).toLocaleTimeString(['pt-br'], {
      timeStyle: "short",
      hour12: false
    })}`
  }

  public static toCardBrand(cardNumber: string): CardBrandType {
    const cardBrandRules: { [key in CardBrandType]: CardBrandRule } = {
      [CardBrandType.Unknown]: { prefixes: [], lengths: [] },
      [CardBrandType.Visa]: { prefixes: ['4'], lengths: [13, 16, 19] },
      [CardBrandType.Mastercard]: { prefixes: ['51', '52', '53', '54', '55'], lengths: [16] },
      [CardBrandType.Elo]: { prefixes: ['4011', '4312', '4389', '4576', '5041', '5067', '5078', '5080', '6500'], lengths: [16, 19] },
      [CardBrandType.Amex]: { prefixes: ['34', '37'], lengths: [15] },
    };

    const isMatchingBrand = (cardNumber: string, rule: CardBrandRule): boolean => {
      const matchesPrefixss = rule.prefixes.find(prefix => cardNumber.startsWith(prefix));
      const matchesPrefix = rule.prefixes.some(prefix => cardNumber.startsWith(prefix));
      const matchesLength = rule.lengths.includes(cardNumber.length);
      return matchesPrefix && matchesLength;
    }
    
    for (const brand in cardBrandRules) {
      const rule = cardBrandRules[brand as unknown as CardBrandType];
      if (isMatchingBrand(cardNumber, rule)) {
        return brand as unknown as CardBrandType; 
      }
    }

    return CardBrandType.Unknown;
  }
}
