import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import styles from './Menu.module.scss'

export interface IMenuProps {
    //propertys
    onClickOut?: () => void
    onClickLogin?: () => void
}
const Menu: FC<IMenuProps> = ({ onClickOut, onClickLogin }) => {

    return (
        <div id={styles.Menu} >
            <div className={styles.menu}>
                <ul>
                    <Link to={"/pedidos"}><li>Pedidos</li></Link>
                    {/* <Link to={"/"+url +"/schedule/tour/reserves"}><li>Reservas</li></Link> */}
                    {onClickLogin && <li onClick={onClickLogin}>Entrar</li>}
                    {onClickOut && <li onClick={onClickOut}>Sair</li>}
                </ul>
            </div>
            <div className={styles.menuFaq}>
                {/* <img src={Help} alt="Logo de ajuda"/>
                <ul>         
                    <Link to={"/"+url +"/schedule/tour/faq"}><li>Ajuda</li></Link>
                </ul> */}
            </div>
        </div>
    )
}
export default Menu