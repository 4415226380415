import { Container, TextField, Grid } from "@material-ui/core";
import React, { FC, FormEvent, useCallback, useState } from "react";
import { GoogleAutoComplete } from "../../components/googleAutocomplete/GoogleAutoComplete";
import { useUi } from "../../context/ui/UIContext";
import { IAddress } from "../../models/address/IAddress";
import AddressApi from "../../services/Api/address/AddressApi";
import { DeliveryAddressItem } from "../payment/deliverySelect/deliveryAddressItem/DeliveryAddressItem";
import styles from './NewAddress.module.scss';

export const NewAddress: FC<{ onCancel?: () => void; onSuccess?: (address: IAddress) => void }> = ({ onCancel, onSuccess }) => {
  const { toast, showLoading, hideLoading } = useUi();
  const [selectedAdress, setselectedAdress] = useState<IAddress | null>(null);

  const [values, setValues] = useState({ number: "", complement: "" });

  const onSubmit = useCallback(
    async (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      if (selectedAdress) {
        try {
          showLoading();
          const response = await AddressApi.saveAddress({
            ...selectedAdress,
            number: selectedAdress.number ?? values.number,
            complement: values.complement,
          });
          const setDefaultResponse = await AddressApi.setDefaultAddress(response.data.id);
          onSuccess &&
            onSuccess({
              ...selectedAdress,
              number: selectedAdress.number ?? values.number,
              complement: values.complement,
            });
          // history.goBack();//todo: temporario

          toast("Endereço adicionado com sucesso", "success");
        } catch (error: any) {
          if (error?.reponse?.data?.message) {
            toast(error?.reponse?.data?.message, "error");
          }
        } finally {
          hideLoading();
        }
      }
    },
    [hideLoading, onSuccess, selectedAdress, showLoading, toast, values]
  );

  const changeNumeroHandle = useCallback((values: string) => {
    setValues((prev) => ({ ...prev, number: values }));
  }, []);
  const changeComplementoHandle = useCallback((values: string) => {
    setValues((prev) => ({ ...prev, complement: values }));
  }, []);

  return (
    <Container
      maxWidth="xs"
      className={styles.modalContainer}
    >
      <h4>Adicionar Endereço</h4>
      <Grid spacing={2} container>
        <Grid xs={12} item>
          <GoogleAutoComplete
            onSelect={(address) => {
              setselectedAdress(address);
            }}
            onReset={() => {
              setselectedAdress(null);
              setValues({ number: "", complement: "" });
            }}
          />
          {selectedAdress && <DeliveryAddressItem address={selectedAdress} />}
        </Grid>
        <Grid xs={12} item>
          <form onSubmit={onSubmit}>
            <Grid spacing={2} container>
              <Grid xs={12} item>
                {selectedAdress && !selectedAdress.number && (
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name={"number"}
                    onChange={(ev) => changeNumeroHandle(ev.target.value)}
                    value={values.number}
                    label={"Numero"}
                    inputProps={{ inputMode: "numeric" }}
                  />
                )}
              </Grid>
              <Grid xs={12} item>
                {selectedAdress && (
                  <TextField
                    fullWidth
                    variant="outlined"
                    name={"complement"}
                    onChange={(ev) => changeComplementoHandle(ev.target.value)}
                    value={values.complement}
                    label={"Complemento"}
                  />
                )}
              </Grid>

              <div className={styles.buttonsContainer}>
                <button
                  onClick={() => {
                    onCancel && onCancel();
                  }}
                  className={styles.modalCancelButton}
                >
                  Voltar
                </button>

                <button
                  onClick={() => { }}
                  type="submit"
                  disabled={!selectedAdress?.number && !values.number}
                  className={styles.modalSubmitButton}
                >
                  Salvar Endereço
                </button>
              </div>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};
